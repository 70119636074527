import * as prismic from '@prismicio/client'

// Fill in your repository name
export const repositoryName = 'simonv';

export const prismicClient = prismic.createClient(repositoryName, {
  // If your repository is private, add an access token
  accessToken: 'MC5aaWVfdGhBQUFDQUEtMDFl.G--_vXDvv70ITm9bADHvv73vv73vv73vv73vv71-XO-_vSg277-977-977-9ai7vv73vv73vv73vv73vv70NdA',

  // This defines how you will structure URL paths in your project.
  // Update the types to match the custom types in your project, and edit
  // the paths to match the routing in your project.
  //
  // If you are not using a router in your project, you can change this
  // to an empty array or remove the option entirely.
  routes: [],
})