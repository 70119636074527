import * as React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { PrismicProvider } from "@prismicio/react";
import { prismicClient } from "./prismic";

createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <PrismicProvider client={prismicClient}>
      <App />
    </PrismicProvider>
  </React.StrictMode>,
);