import React from "react";
import "./styles.css";
import { createBrowserRouter, NavLink, Outlet, RouterProvider, useLocation } from "react-router-dom";
import { Overview } from "./pages/Overview";
import { About } from "./pages/About";
import { OverviewPreloader } from "./pages/OverviewPreloader";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        element: <OverviewPreloader />,
      },
      {
        path: "/about",
        element: <About />,
      },
    ],
  },

]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

function Root() {
  const { pathname } = useLocation();

  return (
    <>
      <header>
        <nav role="navigation">
          <h4>Simon Viðarsson</h4>
          <a href="/" className={pathname === '/' ? 'active-page' : ''}>Overview</a>
          <NavLink to="/about" className={(props) => props.isActive ? "active-page" : ""}>About</NavLink>
        </nav>
      </header>
      <Outlet />
    </>
  );
}

export default App;
