import React, { ReactNode, useMemo } from "react";
import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { RTNode } from "@prismicio/client";
import { Overview } from "./Overview";
import useImagePreloader from "../hooks/useImagePreloader";

interface ProjectImage {
  alt: string | null;
  dimensions: { width: number; height: number };
  id: string;
  url: string;
}

export interface Project {
  title: [RTNode, ...RTNode[]];
  description: [RTNode, ...RTNode[]];
  body: {
    items: { project_image: ProjectImage }[];
  }[];
}

const Loader = () => {
  return (
    <div className={"loading-fullscreen"}>
      <h4>Bing Bang Bing Ding Ding Diga Dey D-Dey Da Dey Da Dee.</h4>
    </div>
  );
};

export const OverviewPreloader = () => {
  const [documents, { state }] = useAllPrismicDocumentsByType("projects", {
    orderings: {
      field: "document.first_publication_date desc",
    },
  });

  const projects = (documents ?? []).map((d) => d.data as Project);

  const allImages = useMemo(() => {
    return [...projects].flatMap(p => p.body).flatMap(b => b.items).flatMap(c => c.project_image);
  }, [projects]);

  if (state === "loading" || (!allImages || allImages.length === 0)) {
    return <Loader />;
  }

  return (
    <ImagesPreloader projects={projects} images={allImages} />
  );
};

const ImagesPreloader = ({ projects, images }: { projects: Project[], images: ProjectImage[]; }) => {
  const imagePreloader = useImagePreloader(images.map(i => i.url));

  if (!imagePreloader.imagesPreloaded) {
    return <Loader />;
  }

  return (
    <Overview projects={projects} totalImages={images.length} />
  );
};