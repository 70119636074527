import React from "react";

export const About = () => {
  return (
    <div className={'about-page'}>
      <div className={'about-text'}>
        <h1>I'm a Graphic Designer and Art Director from Reykjavík, Iceland. I graduated from IUA, the Iceland University
          of the Arts, in 2020 with a bachelor's degree in Graphic Design. In recent years, I've developed a strong
          passion for Typography, Type Design, Digital Design, and Visual Identities. After graduating from IUA, I started
          working at a Design Studio called Kolofon, and later moved to a Digital Agency called Gangverk. I'm currently
          working as an Art Director at Kolibri since February 2023.</h1>
        <div style={{ height: "var(--space-3)" }} />
        <h5>Contact</h5>
        <div>
          <a href={"tel:+3547834655"}>+354 783 4655</a>
        </div>
        <div>
          <a href={"mailto:simonvidars@gmail.com"}>simonvidars@gmail.com</a>
        </div>
      </div>
      <div className={'about-photo'}>
        <img src={"https://images.prismic.io/simonv/Ziq6s_Pdc1huK1_M_Portrait%402x.jpg?auto=format,compress"} alt={"by Kjartan Hreinsson"} />
      </div>
      <div className={'about-social'}>
        <h5>Instagram</h5>
        <div>
          <a href={"https://www.instagram.com/simonvidarsson"} target={"_blank"} rel="noreferrer">@simonvidarsson</a>
        </div>
      </div>
      <div className={'about-credits'}>
        <h5>Photo</h5>
        <div>
          <span>Kjartan Hreinsson</span>
        </div>
      </div>
    </div>
  );
};