import React, { useEffect, useRef, useState } from "react";
import { PrismicRichText, PrismicText } from "@prismicio/react";
import { motion, useScroll, useTransform } from "framer-motion";
import { Project } from "./OverviewPreloader";

export const Overview = ({ projects, totalImages }: { projects: Project[], totalImages: number; }) => {
  const [imagesLoaded, setImagesLoaded] = useState(0);
  // Overview component
  const overviewRef = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    layoutEffect: false,
    target: overviewRef,
  });
  const x = useTransform(scrollYProgress, [0, 1], ["0", "-90.5%"]);

  // Projects component
  const projectsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (overviewRef.current && projectsRef.current && imagesLoaded >= totalImages) {

      const projects = projectsRef.current.querySelectorAll(".project");
      const inner = projectsRef.current.querySelector(".inner") as HTMLDivElement;

      let widthOfProjects = 0;

      if (projects && projects.length > 0) {
        projects.forEach((p) => {
          widthOfProjects += p.clientWidth;
        });
      }

      setTimeout(() => {
        if (inner) {
          inner.style.transform = "translateX(0%) translateZ(0px)";
        }
      }, 10);

      overviewRef.current.style.height = `${widthOfProjects}px`;
    }
  }, [imagesLoaded]);

  return (
    <div className="overview" id={"overview"} ref={overviewRef}>
      <div className="projects" ref={projectsRef}>
        <motion.div className="inner" style={{ x, display: "flex", willChange: "transform" }}>
          {projects.map((p, pIndex) => (
            <div className="project" key={`p_${pIndex}`}>
              <div className="project-images">
                {p.body.length > 0 && (
                  <>
                    {p.body[0].items.map((i) => (
                      <img
                        alt={i.project_image.alt ?? ""} key={i.project_image.id} src={i.project_image.url}
                        onLoad={() => {
                          setImagesLoaded(l => l + 1);
                        }}
                      />
                    ))}
                  </>
                )}
              </div>
              <div className="project-description">
                <h1>
                  <PrismicText field={p.title} />
                </h1>
                <PrismicRichText field={p.description} />
              </div>
            </div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};
